// Start.js

import React from 'react';

function Start() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Autokanta.fi - kaikki Suomen autot</h1>
      <h2>Tulossa pian.</h2>
    </div>
  );
}


export default Start;

