// Step1.js

import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from './App';

function Step1() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchStatus, setSearchStatus } = useContext(AppContext);

  // Set the current step status when Step1 is mounted
  useEffect(() => {
    console.log('Step1, useEffect, searchStatus', searchStatus);
    setSearchStatus('Step1');
  }, []);

  console.log('Step1');
  console.log('location.state', location.state);

  const handleNext = () => {
    // Navigate to Step2 and pass state data
    navigate('/step2', { state: { from: 'coming from /step1' } });
  };

  return (
    <div>
      <h1>Step 1 - searchStatus {searchStatus} location.state {location.state?.from}</h1>
      <button onClick={handleNext}>Next</button>
    </div>
  );
}

export default Step1;




// import React, { useEffect, useContext } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { AppContext } from './App'; // Import the AppContext


// function Step1() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   // const { searchStatus, setSearchStatus, otherVariable, setOtherVariable } = useContext(AppContext);
//   const { searchStatus, setSearchStatus, otherVariable } = useContext(AppContext);

//   // Update searchStatus only when the component is mounted
//   useEffect(() => {
//     console.log('Step1, useEffect, searchStatus', searchStatus);
//     setSearchStatus('Step1');
//   }, []); 

//   console.log('Step1');
//   console.log('location.state', location.state);

//   const handleNext = () => {
//     navigate('/step2', { state: { from: 'coming from /step1' } });
//   };

//   return (
//     <div>
//       <h1>Step 1 - searchStatus { searchStatus } - otherVariable { otherVariable } </h1>
//       <button onClick={handleNext}>Next</button>
//     </div>
//   );
// }

// export default Step1;
