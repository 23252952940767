// Step3.js

import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from './App';

export const getTimeStamp = () => {
  const now = new Date();
  return `${now.toLocaleTimeString([], { hour12: false })}.${now.getMilliseconds()}`;
};

function Step3() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchStatus, setSearchStatus, stepValidation } = useContext(AppContext);

  useEffect(() => {
    // Redirect if access is not validated
    if (!stepValidation) {
      console.log('Unauthorized access to Step3. Redirecting to Step1...');
      navigate('/step1');
    } else {
      console.log(getTimeStamp(), 'Step3, useEffect, searchStatus', searchStatus);
      setSearchStatus('Step3');
    }
  }, [stepValidation, navigate, searchStatus, setSearchStatus]);

  return (
    <div>
      <h1>Step 3 - searchStatus {searchStatus} location.state {location.state?.from}</h1>

    </div>
  );
}

export default Step3;



// import React, { useEffect, useContext } from 'react';
// // import { useLocation } from 'react-router-dom';
// import { AppContext } from './App'; // Import the AppContext

// import { useNavigate } from 'react-router-dom';

// // Returns a string with hh:mm:ss:ms
// export const getTimeStamp = () => {
//   const now = new Date();
//   return `${now.toLocaleTimeString([], { hour12: false })}.${now.getMilliseconds()}`;
// };

// function Step3() {
//   const navigate = useNavigate();
//   const { searchStatus, setSearchStatus, stepValidation } = useContext(AppContext);

//   useEffect(() => {
//     // Check if user is allowed to access Step3
//     if (!stepValidation) {
//       console.log('Unauthorized access to Step3. Redirecting to Step1...');
//       navigate('/step1'); // Redirect if invalid
//     } else {
//       console.log(getTimeStamp(), 'Step3, useEffect, searchStatus', searchStatus);
//       setSearchStatus('Step3');
//     }
//   }, [stepValidation, navigate, searchStatus, setSearchStatus]);

//   return (
//     <div>
//       <h1>Step 3 - searchStatus  { searchStatus }</h1>
//     </div>
//   );
// }

// export default Step3;

