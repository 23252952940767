// Step3.js

import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from './App';

export const getTimeStamp = () => {
  const now = new Date();
  return `${now.toLocaleTimeString([], { hour12: false })}.${now.getMilliseconds()}`;
};

function Step2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchStatus, setSearchStatus, setStepValidation } = useContext(AppContext);

  useEffect(() => {
    console.log(getTimeStamp(), 'Step2, useEffect, searchStatus', searchStatus);

    // Redirect user to Step1 if they try to access Step2 improperly
    if (searchStatus === 'Step3') {
      console.log(getTimeStamp(), 'Step2, useEffect, navigating to step1');
      navigate('/step1');
    }

    setSearchStatus('Step2'); // Update the current step status
  });

  console.log(getTimeStamp(), 'location.state', location.state);

  const handleNext = () => {
    // Validate access to Step3 and navigate
    setStepValidation(true);
    navigate('/step3', { state: { from: 'coming from /step2' } });
  };

  return (
    <div>
      <h1>Step 2 - searchStatus {searchStatus} location.state {location.state?.from}</h1>
      <button onClick={handleNext}>Next</button>
    </div>
  );
}

export default Step2;




// import React, { useEffect, useContext } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { AppContext } from './App'; // Import the AppContext


// // Returns a string with hh:mm:ss:ms
// export const getTimeStamp = () => {
//   const now = new Date();
//   return `${now.toLocaleTimeString([], { hour12: false })}.${now.getMilliseconds()}`;
// };

// function Step2() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { searchStatus, setSearchStatus, setStepValidation } = useContext(AppContext);

//   useEffect(() => {
//     console.log(getTimeStamp(), 'Step2, useEffect, searchStatus', searchStatus);
//     if (searchStatus === 'Step3') {
//       console.log(getTimeStamp(), 'Step2, useEffect, navigating to step1');
//       navigate('/step1');
//     }
//     setSearchStatus('Step2');
//   }); 

//   console.log(getTimeStamp(), 'location.state', location.state);

//   const handleNext = () => {
//     setStepValidation(true); // Allow access to Step3
//     navigate('/step3', { state: { from: 'coming from /step2' } });
//   };

//   return (
//     <div>
//       <h1>Step 2 - searchStatus  { searchStatus } location.state { location.state.from }</h1>
//       <button onClick={handleNext}>Next</button>
//     </div>
//   );
// }

// export default Step2;
