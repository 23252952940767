// App.js

// Synopsis: This React application represents a multi-step navigation flow with three steps (Step1, Step2, and Step3).
// The application uses React Context to manage state across the steps, enabling shared data and validation logic. 
// Each step is implemented as a separate component, and the navigation flow is handled by react-router-dom. 
// Unauthorized access to Step3 is intercepted and redirected to Step1.


import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Start from './Start';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

// Create a shared context to manage state across components
export const AppContext = createContext();

const xanaduVersion = '0.1.1'; // 2024-12-06
console.log('xanaduVersion', xanaduVersion);

function App() {
  // Shared state to track the current step status and step validation
  const [searchStatus, setSearchStatus] = useState('not started'); // Tracks the user's progress
  const [stepValidation, setStepValidation] = useState(false); // Validates access to Step3

  return (
    // Provide state and updater functions through context
    <AppContext.Provider value={{
      searchStatus, setSearchStatus,
      stepValidation, setStepValidation,
    }}>
      <Router>
        <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/step1" element={<Step1 />} />
        <Route path="/step2" element={<Step2 />} />
          <Route path="/step3" element={<Step3 />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default App;



// import React, { createContext, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Step1 from './Step1';
// import Step2 from './Step2';
// import Step3 from './Step3';

// export const AppContext = createContext();

// function App() {
//   const [searchStatus, setSearchStatus] = useState('not started');
//   const [stepValidation, setStepValidation] = useState(false); // New validation state

//   return (
//     <AppContext.Provider value={{
//       searchStatus, setSearchStatus,
//       stepValidation, setStepValidation, // Provide the new state
//     }}>
//       <Router>
//         <Routes>
//           <Route path="/step1" element={<Step1 />} />
//           <Route path="/step2" element={<Step2 />} />
//           <Route path="/step3" element={<Step3 />} />
//         </Routes>
//       </Router>
//     </AppContext.Provider>
//   );
// }

// export default App;
